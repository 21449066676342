var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"title":"Product List"}},[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[(_vm.checkPermission('add product'))?_c('router-link',{staticClass:"btn waves-effect waves-float waves-light btn-primary",attrs:{"to":"/create-product-list","variant":"primary"}},[_vm._v(" Add Product ")]):_vm._e()],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('form',{staticClass:"form-inline justify-content-end"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"position-relative"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter.keyword),expression:"filter.keyword"}],staticClass:"form-control",attrs:{"type":"search","placeholder":"Search: Name Product","aria-label":"Search"},domProps:{"value":(_vm.filter.keyword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.filter, "keyword", $event.target.value)}}})]),_c('button',{staticClass:"btn btn-outline-secondary ml-sm-2",attrs:{"disabled":!_vm.filter.keyword},on:{"click":function($event){_vm.filter.keyword = ''}}},[_vm._v(" Reset ")])])])])],1),_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.products,"fields":_vm.fields,"busy":_vm.isLoading,"show-empty":"","responsive":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-secondary my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(no)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s((_vm.currentPage - 1) * 10 + index + 1)+" ")]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [(item.status == 'show')?_c('b-badge',{attrs:{"variant":"light-success"}},[_vm._v(" Show ")]):_c('b-badge',{attrs:{"variant":"light-danger"}},[_vm._v(" Hide ")])]}},{key:"cell(image)",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"product__image",attrs:{"src":item.image,"alt":""}})]}},{key:"cell(is_default)",fn:function(ref){
var item = ref.item;
return [(item.is_default == true)?_c('b-badge',{attrs:{"variant":"light-success"}},[_vm._v(" True ")]):_c('b-badge',{attrs:{"variant":"light-danger"}},[_vm._v(" False ")])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"gap":"12px"}},[(_vm.checkPermission('update product'))?_c('router-link',{attrs:{"to":'/edit-product-list/'+ item.uuid}},[_c('b-badge',{staticClass:"badge-glow",attrs:{"variant":"warning"}},[_c('feather-icon',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Edit Data'),expression:"'Edit Data'",modifiers:{"hover":true,"bottom":true}}],staticClass:"cursor-pointer",attrs:{"id":("invoice-row-" + (item.uuid) + "-send-icon"),"icon":"EditIcon","size":"16"}})],1)],1):_vm._e(),_c('b-badge',{staticClass:"badge-glow",attrs:{"variant":"danger"}},[(_vm.checkPermission('delete product'))?_c('feather-icon',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Delete Data'),expression:"'Delete Data'",modifiers:{"hover":true,"bottom":true}}],attrs:{"id":("invoice-row-" + (item.uuid) + "-preview-icon"),"icon":"TrashIcon","size":"16"},on:{"click":function($event){return _vm.deleteItem(item.uuid)}}}):_vm._e()],1)],1)]}}])}),(_vm.result.total > 0)?_c('div',{staticClass:"m-1"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col mb-1"},[_c('small',[_vm._v("Showing "+_vm._s(_vm.result.from)+" to "+_vm._s(_vm.result.to)+" from "+_vm._s(_vm.result.total))])]),_c('div',{staticClass:"col"},[_c('pagination',{attrs:{"data":_vm.result,"limit":4,"align":"right"},on:{"pagination-change-page":_vm.getData}})],1)])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }